@use '@/styles/utils/mixins.scss' as *;

.mediaCard {
  background-color: $white;
  padding: 1.875rem 1.25rem;
  border-radius: 1.25rem;
  border: 0.063rem solid $white;
  transition: all 0.2s ease-in-out;
  -webkit-box-shadow: 0rem 0rem 0rem 0rem rgba($dark, 0);
  -moz-box-shadow: 0rem 0rem 0rem 0rem rgba($dark, 0);
  box-shadow: 0rem 0rem 0rem 0rem rgba($dark, 0);
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  &.shadowBoxCard {
    -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
    -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
    box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  }

  &.landscapeCard {
    @include tab() {
      flex-direction: row;
      align-items: center;
      padding: 1.25rem;
      .mediaCardImageContainer {
        height: 5rem;
        width: 5rem;
      }
      .metaData {
        font-size: 0.875rem;
      }
      .tagsList {
        gap: 0.5rem;
        .tag {
          font-size: 0.75rem;
        }
      }
      :global {
        .titleSm {
          font-size: 1rem;
        }
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    border-color: $primaryAlt;
    -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
    -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
    box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
  }

  .mediaCardImageContainer {
    position: relative;
    flex: 0 0 auto;
    height: 21.25rem;

    .mediaCardImage {
      border-radius: 1.25rem;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }

  .mediaCardBody {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    flex: 1 1 auto;
  }
  .mediaCardContent {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    flex: 1 1 auto;
  }
  .description {
    font-size: 1rem;
    color: $dark;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .metaData {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 0.5rem;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: auto;
    font-size: 1rem;
    color: $neutral07;
    li {
      display: flex;
      gap: 0.5rem;
      align-items: baseline;
      &:not(:last-child)::after {
        content: '|';
      }
    }
  }
  .tagsList {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: auto;
    .tag {
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
      border-radius: 1.5rem;
      border: 0.063rem solid $primary;
      color: $primary;
      line-height: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
    }
  }
}
